import React, { useEffect, useState } from "react";
import "../Browse.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Loader from "../common/Loader";
import instance, { APP_COLORS, APP_IMAGES } from "../common/Api";
const ShowMore = () => {
  const [containerHeight, setContainerHeight] = useState("auto");
  const [hoveredCard, setHoveredCard] = useState(null);
  const [browseData, setBrowseData] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { Data, title, url_title } = location.state;
  const handleClick = (item) => {
    navigate("/play", { state: { someData: item } });
    // navigate("/media/", { state: { someData: item } });
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };
  useEffect(() => {
    const fetchDataWithDelay = setTimeout(() => {
      instance
        .post(`/see_all?title=${url_title}&id=1`)
        .then((response) => {
          const resData = response.data;
          // console.log(resData, "resData");
          setBrowseData(resData.data);
          setPageTitle(title);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000); // Delay of 1 second

    return () => clearTimeout(fetchDataWithDelay);
    function handleResize() {
      // setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useEffect(() => {
  //   if (Data.mediaContentList != null) {
  //     setBrowseData(Data.mediaContentList);
  //     setPageTitle(title);
  //     console.log("Data;;;");
  //   }

  //   const handleResize = () => {
  //     // Calculate the height based on the width to maintain a 16:9 aspect ratio
  //     const width =
  //       window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  //     const height = (width * 9) / 16;
  //     setContainerHeight(`${height}px`);
  //   };

  //   // Call the handleResize function on initial load and whenever the window size changes
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "55rem",
        position: "relative",
        backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_MOB})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        backgroundBlendMode: "overlay",
      }}
    >
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}
      <Container fluid>
        <Row className="d-flex justify-content-center text-center searchRow mb-4">
          <div
            style={{ display: "flex", alignItems: "center", height: "40px" }}
          >
            <BsArrowLeft
              style={{
                color: APP_COLORS.APP_TEXT,
                marginRight: "10px",
                fontSize: "25px",
              }}
              onClick={handleBack}
            />
            <h4
              style={{
                color: APP_COLORS.APP_TEXT,
                marginBottom: "15px",
                marginLeft: "10px",
                textAlign: "left",
                marginTop: "15px",
                display: "inline-block", // Ensure inline display
                verticalAlign: "middle", // Vertically align the text
              }}
            >
              {pageTitle}
            </h4>
          </div>
        </Row>
      </Container>
      <Container
        style={{ height: containerHeight * 100, paddingBottom: "auto" }}
      >
        <Row className="pb-5">
          {browseData &&
            browseData.map((movie, index) => (
              <Col
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={index}
                className="image-item"
              >
                <img
                  src={movie.browse_image || APP_IMAGES.PLACE_VERTI_IMG}
                  alt={`Image ${index + 1}`}
                  className={`image ${
                    hoveredCard === index ? "zoomed-card" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(movie)}
                  style={{ aspectRatio: "9 / 14" }}
                  onError={(e) => {
                    e.target.src = APP_IMAGES.PLACE_HORIZ_IMG;
                  }}
                />
                <h2
                  className={`image-title ${
                    hoveredCard === index ? "zoomed-card" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)} //browse_image
                  onMouseLeave={handleMouseLeave}
                >
                  {movie.title}
                </h2>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default ShowMore;
